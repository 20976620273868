<template>
  <div class="p-wrap">
    <div class="p-main">
      <div class="main-title">
        <el-button type="primary" @click="handleSave" :disabled="btnSaveDisabled" :loading="btnSaveLoading">保存修改
        </el-button>
        <el-button type="default" @click="handleView">{{ btnViewName }}</el-button>
        <el-button type="default" @click="handleBack">返回</el-button>
      </div>
      <div class="xlsx">
        <div v-loading="tableLoading" id="template"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { selectDeclare, saveSheets } from '@/service/declare/index'
import { baseURL } from '@/service/index'

export default {
  name: 'DecWbsjXq',
  computed: {
    // 全屏按钮名称
    btnViewName () {
      return this.isFullScreen ? '退出' : '全屏'
    },
    btnSaveDisabled () {
      let statuses = [undefined, '411', '302']
      return this.btnSaveLoading || (statuses.indexOf(this.declare.declareStatus) > -1)
    }
  },
  data () {
    return {
      declare: { declareStatus: undefined, externalTableId: undefined },
      id: this.$route.query.id,
      isFullScreen: false,
      btnSaveLoading: false,
      tableLoading: false
    }
  },
  created () {
    document.addEventListener('webkitfullscreenchange', () => {
      if (!document.webkitIsFullScreen && this.isFullScreen == true)
        this.handleView()
    })
    this.init()
  },
  methods: {
    // 初始数据
    async init () {
      console.log('declare id:', this.id)
      const { success, data } = await selectDeclare(this.id)
      if (success) {
        this.declare = data
      }
      if (this.declare.externalTableId)
        await this.initXlsx()
      else
        this.toast('无效的处部数据主键！', 'warning')
    },
    // 保存表格
    async handleSave () {
      let sheets = luckysheet.getAllSheets()
      this.btnSaveLoading = true
      const { success } = await saveSheets({
        id: this.declare.externalTableId,
        sheets: sheets.map(i => {
          return {
            sheet: i
          }
        })
      })
      this.btnSaveLoading = false
      if (success)
        this.toast('保存成功', 'success')
    },
    // 全屏现实
    handleView () {
      this.isFullScreen = !this.isFullScreen
      this.isFullScreen ? document.body.webkitRequestFullscreen() : document.webkitExitFullscreen()
      this.$bus.$emit('full-screen', this.isFullScreen)
    },
    // 返回主页
    handleBack () {
      this.$router.replace('/declare/ent/external/list')
    },
    // 初始表格
    async initXlsx () {
      this.tableLoading = true
      let options = {
        container: 'template',
        loadUrl: `${baseURL}/declare/data/table/${this.declare.externalTableId}`,
        // 表格控制配置
        allowEdit: !this.btnSaveDisabled,
        enableAddRow: false,
        enableAddCol: false,
        userInfo: false,
        showsheetbarConfig: {
          add: false,
          menu: false
        },
        showtoolbarConfig: {
          function: true,
          postil: true,
          findAndReplace: true
        },
        forceCalculation: true,
        showinfobar: false,
        showtoolbar: false,
        allowCopy: false,
        lang: 'zh',
        hook: {
          cellAllRenderBefore: () => {
            this.tableLoading = false
          }
        }
      }
      luckysheet.create(options)
    }
  }
}
</script>

<style scoped lang="scss">
.p-wrap {
  display: flex;
  flex-direction: column;
}

.p-header {
  width: auto;
  padding: 24px 24px 0;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);

  .p-tip {
    display: flex;
    padding: 14px 16px;
    margin-bottom: 14px;
    flex-direction: row;
    align-items: center;
    background-color: #EBF4FF;
    justify-content: space-between;

    .tip-label {
      color: #333333;
      font-size: 16px;
    }

    .tip-area {
      .radio {
        display: inline-block;
        margin-right: 20px;

        span {
          color: #666666;
          margin-left: 8px;
          vertical-align: top;
        }
      }

      .radio:hover {
        cursor: pointer;
      }
    }
  }

  .p-select {
    display: flex;
    color: #666666;
    padding: 0 16px 14px;
    flex-direction: column;
    justify-content: center;

    .row {
      padding: 10px;
      display: flex;
      flex-direction: row;

      i {
        width: 160px;
        font-style: normal;
        display: inline-block;
      }
    }

    .label {
      min-width: 160px;
    }

    .items {
      flex: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      span {
        margin-right: 8px;
      }
    }
  }

}

.p-main {
  display: flex;
  padding: 12px 24px;
  flex-direction: column;
  background-color: #fff;

  .main-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .history {
      color: #1890FF;
      font-size: 16px;
      font-weight: 500;
    }

    .history:hover {
      cursor: pointer;
    }
  }

  .xlsx {
    margin-top: 12px;

    #template {
      display: block;
      height: 100%;
      min-height: 650px;
      width: 100%;
    }
  }
}
</style>
